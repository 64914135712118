<template>
  <div>
    <vx-card :title="$t(resources.Buy.i18n) || resources.Buy.name">
      <vs-row vs-type="flex" vs-justify="center">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <img src="../../assets/images/logo/neo.png" alt class="imag-blur" />
        </vs-col>
      </vs-row>

      <vs-divider class="mb-2">
        <!-- <strong>{{$t(resources.CreditCard.i18n) || resources.CreditCard.name}}:</strong> -->
        <ul class="centerx">
          <li>
            <vs-radio v-model="wayToPay" vs-value="TC">
              {{
                $t(resources.CreditCard.i18n) || resources.CreditCard.name
              }}&nbsp;&nbsp;
            </vs-radio>
            <vs-radio v-model="wayToPay" vs-value="BE">E-wallet</vs-radio>
          </li>
        </ul>
      </vs-divider>
      <div v-if="wayToPay == 'TC'">
        <h3 class="text-center text-danger">
          {{
            $t(alerts.MsgAllRequiredField.i18n) ||
            $t(alerts.MsgAllRequiredField.name)
          }}
          *
        </h3>
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/6 mb-1">
            <vs-select
              v-validate="'required'"
              class="w-full vs-component vs-con-input-label vs-input is-label-placeholder"
              icon-no-border
              required="true"
              :placeholder="
                $t(resources.CardType.i18n) || resources.CardType.name
              "
              v-model="cardType"
            >
              <vs-select-item
                :key="index"
                :value="item.cardId"
                :text="item.name"
                v-for="(item, index) in cardTypeOption"
              />
            </vs-select>
          </div>
          <div class="vx-col w-full md:w-1/4 mb-1">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              icon-no-border
              :label-placeholder="
                $t(resources.CardNumber.i18n) || resources.CardNumber.name
              "
              v-model="cardNumber"
              :value="cardNumber"
              :maxlength="16"
              @keypress="onlyNumber"
            />
          </div>
          <div class="vx-col w-full md:w-1/6 mb-1">
            <vs-select
              class="w-full input-select-con vs-component vs-con-input-label vs-input is-label-placeholder"
              icon-no-border
              :placeholder="
                $t(resources.ExpirationYear.i18n) ||
                resources.ExpirationYear.name
              "
              v-model="expirationYear"
              v-on:change="expirationYearChange($event)"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in expirationYearOption"
              />
            </vs-select>
          </div>
          <div class="vx-col w-full md:w-1/5 mb-1">
            <vs-select
              class="w-full input-select-con vs-component vs-con-input-label vs-input is-label-placeholder"
              icon-no-border
              :placeholder="
                $t(resources.ExpirationMonth.i18n) ||
                resources.ExpirationMonth.name
              "
              v-model="expirationMonth"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in expirationMonthOption"
              />
            </vs-select>
          </div>
          <div class="vx-col w-full md:w-1/5 mb-1">
            <vs-input
              type="password"
              class="w-full"
              icon-pack="feather"
              icon="icon-lock"
              icon-no-border
              :label-placeholder="
                $t(resources.SecurityCode.i18n) || resources.SecurityCode.name
              "
              v-model="securityCode"
              @keypress="onlyNumber"
              :maxlength="4"
            />
          </div>
        </div>

        <vs-divider class="mb-2">
          <strong>
            {{
              $t(resources.BasicInformation.i18n) ||
              resources.BasicInformation.name
            }}:
          </strong>
        </vs-divider>
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/3 mb-1">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              icon-no-border
              :label-placeholder="
                $t(resources.Names.i18n) || resources.Names.name
              "
              v-model="name"
              :maxlength="30"
            />
          </div>
          <div class="vx-col w-full md:w-1/3 mb-1">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              icon-no-border
              :label-placeholder="
                $t(resources.LastName.i18n) || resources.LastName.name
              "
              v-model="surname"
              :maxlength="30"
            />
          </div>
          <div class="vx-col w-full md:w-1/3 mb-1">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              icon-no-border
              :label-placeholder="
                $t(resources.DocumentNumber.i18n) ||
                resources.DocumentNumber.name
              "
              v-model="documentNumber"
              :maxlength="20"
              @keypress="onlyNumber"
            />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full md:w-1/3 mb-1">
            <vs-input
              type="email"
              class="w-full"
              icon-pack="feather"
              icon="icon-mail"
              icon-no-border
              :label-placeholder="
                $t(resources.Email.i18n) || resources.Email.name
              "
              v-model="Email"
              :maxlength="50"
            />
          </div>
          <div class="vx-col w-full md:w-1/3 mb-1 mt-4">
            <v-select
              v-model="country"
              :clearable="true"
              :options="countryOption"
              :reduce="(country) => country.id"
              label="name"
              :placeholder="
                $t(resources.Country.i18n) || resources.Country.name
              "
              v-on:input="getStateGetByCountry(country)"
              :hint="`${country}`"
            />
          </div>
          <div class="vx-col w-full md:w-1/3 mb-1 mt-4">
            <v-select
              v-model="state"
              :clearable="true"
              :options="stateOption"
              :reduce="(state) => state.id"
              label="name"
              :placeholder="$t(resources.State.i18n) || resources.State.name"
              v-on:input="getCityGetByState(state)"
              :hint="`${state}`"
            />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col w-full md:w-1/3 mb-1 mt-4">
            <v-select
              v-model="residenceCity"
              :clearable="true"
              :options="cityOption"
              :reduce="(state) => state.id"
              label="name"
              :placeholder="
                $t(resources.ResidenceCity.i18n) || resources.ResidenceCity.name
              "
            />
          </div>
          <div class="vx-col w-full md:w-1/3 mb-1">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-map-pin"
              icon-no-border
              :label-placeholder="
                $t(resources.Address.i18n) || resources.Address.name
              "
              v-model="address"
              :maxlength="150"
            />
          </div>
          <div class="vx-col w-full md:w-1/3 mb-1">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              icon-no-border
              :label-placeholder="
                $t(resources.ZipCode.i18n) || resources.ZipCode.name
              "
              v-model="zipCode"
              @keypress="onlyNumber"
              :maxlength="6"
            />
          </div>
        </div>
      </div>
      <div v-else-if="wayToPay == 'BE'">
        <h4 class="text-center mb-4">
          {{
            $t(alerts.MsgAccountDiscount.i18n) ||
            $t(alerts.MsgAccountDiscount.name)
          }}:
        </h4>
        <h3 class="text-center">
          <strong>{{ userName }}</strong>
        </h3>
        <br />
      </div>

      <h1 class="font-weight-bold text-center text-primary mt-4 mb-2">
        USD {{ productPrice }}
      </h1>
      <vs-row vs-type="flex" vs-justify="center">
        <vs-button class="mt-0" @click="pay" :disabled="!validateForm">{{
          $t(resources.Pay.i18n) || resources.Pay.name
        }}</vs-button>
        <vs-button
          color="rgb(174,174,174)"
          @click="$router.push('/purchases/index')"
          class="mt-0 ml-2"
          >{{ $t(resources.Return.i18n) || resources.Return.name }}</vs-button
        >
      </vs-row>
    </vx-card>
  </div>
</template>
<script>
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import vSelect from "vue-select";
import database from "@/assets/resources/enums/database.json";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      database: database,
      wayToPayId: 0,
      wayToPayList: [],
      wayToPay: "TC",
      alerts: alerts,
      resources: resources,
      invoice: "",
      productPrice: 0,
      productId: 0,
      itaCode: JSON.parse(localStorage.getItem("userInfo")).id,
      accesData: [],
      Email: "",
      cardType: "",
      cardTypeOption: [],
      cardOwner: "",
      expirationMonth: "",
      currentYear: 0,
      currentMonth: 0,
      expirationMonthOption: [],
      expirationYear: "",
      expirationYearOption: [],
      securityCode: "",
      zipCode: "",
      name: "",
      surname: "",
      documentNumber: "",
      Phone: "",
      country: "",
      countryOption: [],
      stateOption: [],
      cityOption: [],
      state: "",
      residenceCity: "",
      address: "",
      totalPagar: 0,
      cardNumber: "",
      userName: JSON.parse(localStorage.getItem("userInfo")).itaCode,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  created: async function () {
    this.$vs.loading();

    await this.getProduct();
    await this.getCountries();
    await this.getYearsCrediCard();
    await this.WayToPay();

    this.$vs.loading.close();
  },
  methods: {
    lengthCardNumber(event) {
      if (String(event).length <= 16) {
        this.cardNumber = event;
      }
      this.$forceUpdate();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async getProduct() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/GetByCountry/${this.$i18n.locale.toUpperCase()}/USA/13/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) {
            let product = result.data.find((x) => x.code == "NEO001");
            this.productPrice = product.installments[0].price;
            this.productId = product.id;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async pay() {
      if (this.wayToPay == "TC" && !this.cardType) {
        this.$vs.notify({
          title: this.$i18n.t("MoreInformation"),
          text: this.$i18n.t("MsgRequiredField"),
          color: "warning",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-x-circle",
        });
        return false;
      }
      this.$vs.loading();
      let json = {
        isPurchase: false,
        rateValue: 1,
        purchaseId: 0,
        language: this.$i18n.locale,
        currencyCode: "USD",
        countryCode: "USA",
        purchaseDetail: [
          {
            productId: 13,
          },
        ],
        contactId: JSON.parse(localStorage.getItem("userInfo")).id,
        itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
        applicationId: 6,
        wayToPayId: 0,
        supplierId: 11,
        createdBy: JSON.parse(localStorage.getItem("userInfo")).email,
        purchaseTypeId: 6,
        payment: {},
      };

      if (this.wayToPay == "TC") {
        json.wayToPayId = this.wayToPayList.find(
          (x) => x.code == "TC"
        ).wayToPayId;

        json.payment = {
          creditCardTypeId: this.cardType,
          cardNumber: this.cardNumber,
          securityCode: this.securityCode,
          expirationMonth: this.expirationMonth,
          expirationYear: this.expirationYear,
          documentNumber: this.documentNumber,
          name: this.name,
          lastName: this.surname,
          address: this.address,
          phone: this.phone,
          zipCode: this.zipCode,
          email: this.email,
          cityId: this.residenceCity,
          installmentNumber: 1,
          amount: this.productPrice,
          currencyCode: "USD",
          xid: null,
          cavv: null,
          eci: null,
          transactionId: null,
        };
      } else {
        json.wayToPayId = this.wayToPayList.find(
          (x) => x.code == "BE"
        ).wayToPayId;

        json.payment = {
          WalletId: 4,
          userName: JSON.parse(localStorage.getItem("userInfo")).itaCode,
          amount: 40,
          language: this.$i18n.locale,
        };
      }
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Membership/BuyProduct`,
        data: json,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT"),
        },
      }).then(
        (result) => {
          if (result.data.resultCode == 200) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text:
                this.$i18n.t("MsgPaymentMade") +
                ". <br>" +
                this.$i18n.t("MsgRefreshPage"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check",
            });
            localStorage.setItem("haveNeo", 1);
            setTimeout(() => {
              this.$vs.loading.close();
              window.location.href = "index";
            }, 1000);
          } else {
            this.$vs.notify({
              time: 8000,
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    async WayToPay() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}WayToPay/GetAvailable`,
        data: {
          ApplicationId: this.database.application.OV,
          CountryCode: "USA",
          ProductId: this.productId,
          SupplierId: this.database.supplier.visionTravel,
          Language: this.$i18n.locale.toUpperCase(),
          CurrencyCode: "USD",
        },
        headers: {
          Authorization: "bearer " + localStorage.getItem("tokenVT"),
        },
      }).then(
        (result) => {
          this.wayToPayList = result.data.wayToPay;
          let data = this.wayToPayList.filter((x) => x.code == "TC")[0].options;
          for (let i = 0; i < data.length; i++) {
            this.cardTypeOption.push({
              cardId: data[i].cardId,
              name: data[i].name,
            });
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async getCountries() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT"),
        },
      }).then(
        (result) => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.countryOption = [];
          } else {
            this.countryOption = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async getStateGetByCountry(country) {
      if (this.country != null && this.country != "") {
        await axios({
          method: "GET",
          url: `${
            process.env.VUE_APP_APIVT
          }State/GetByCountry/${this.$i18n.locale.toUpperCase()}/${country}`,
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + localStorage.getItem("tokenVT"),
          },
        }).then(
          (result) => {
            if (result.data == 0) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle",
              });
              this.stateOption = [];
            } else {
              this.stateOption = result.data;
            }
          },
          (error) => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        );
      }
    },

    async getCityGetByState(stateId) {
      if (stateId != null && stateId != "") {
        await axios({
          method: "GET",
          url: `${
            process.env.VUE_APP_APIVT
          }City/GetByState/${this.$i18n.locale.toUpperCase()}/${stateId}`,
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + localStorage.getItem("tokenVT"),
          },
        }).then(
          (result) => {
            if (result.data == 0) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle",
              });
              this.cityOption = [];
            } else {
              this.cityOption = result.data;
            }
          },
          (error) => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        );
      }
    },

    async getYearsCrediCard() {
      var currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
      this.currentYear = parseInt(currentDate.split("/")[0]);
      this.currentMonth = parseInt(currentDate.split("/")[1]);

      this.expirationYearOption = [];
      this.expirationMonthOption = [];
      var iYear = 0;
      for (let item = this.currentYear; iYear <= 20; item++) {
        iYear++;
        this.expirationYearOption.push(item);
      }

      for (let item = this.currentMonth; item <= 12; item++) {
        this.expirationMonthOption.push(item);
      }
    },

    expirationYearChange(event) {
      var yearSelect = event;
      this.expirationMonthOption = [];

      if (this.currentYear === yearSelect) {
        for (let item = this.currentMonth; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      } else {
        for (let item = 1; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      }
    },

    ShowNotify(condition, input) {
      this.$vs.notify({
        title: this.$i18n.t("Alert"),
        text: this.$i18n.t(condition) + "" + this.$i18n.t(input).toLowerCase(),
        color: "warning",
        iconPack: "feather",
        position: "top-right",
        icon: "icon-alert-circle",
      });
    },

    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
  },
};
</script>
